import { toast } from "react-toastify";
import { supabase } from "../supabase";

export function formatNumberInStr(num, large = false) {
  if (!num) return "0";

  if (num >= 1e9) {
    // Billion
    return (
      (num / 1e9).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "B"
    );
  } else if (num >= 1e6) {
    // Million
    return (
      (num / 1e6).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "M"
    );
  } else if (num >= 1e3) {
    // Thousand
    if (!large)
      return (
        (num / 1e3).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }) + "K"
      );
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    // Less than thousand
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }
}
export function getTimeDifferenceStr(lastDate) {
  const now = new Date();
  const lastRewardDate = new Date(lastDate);
  const diffInMs = now - lastRewardDate;

  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return `${diffInDays} days`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hours`;
  } else {
    return `${diffInMinutes} minutes`;
  }
}

export const getPriceToken = async (token) => {
  if (token.toLowerCase() === "usdt" || token.toLowerCase() === "usdc")
    return 1;

  if (token.toLowerCase() === "ghub") {
    const { data } = await supabase.rpc("get_token_price");
    return data.price;
  }

  try {
    const response = await fetch(
      `https://tonapi.io/v2/rates?tokens=${token}&currencies=usd`
    );
    if (!response.ok) {
      throw new Error("Errore nella richiesta");
    }

    const data = await response.json();
    /*  const currencyRate = Object.values(data.rates)[0];

    const usdPrice = Object.values(currencyRate.prices)[0];
    return usdPrice; */

    return data.rates?.[token.toUpperCase()]?.prices?.["USD"] ?? 0;
  } catch (e) {
    throw e;
  }
};
export function copyString(str, msg) {
  navigator.clipboard.writeText(str);
  toast.success(msg ?? "Copied", {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
  });
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
