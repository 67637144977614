import { useEffect, useState } from "react";
import { getReferralLead } from "../firebase";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { RColumn, Space } from "../comps/otherCOmps";
import {
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import TaskUserLead from "./tasklead";

export default function TaskPage() {
  return (
    <>
      <Tit2Tremor>Tasks</Tit2Tremor>
      <Space />
      <TabGroup>
        <TabList>
          <Tab>Leaderboard</Tab>
          <Tab>Other</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TaskUserLead />
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}
