import { useEffect, useState } from "react";
import { RColumn, RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { supabase } from "../supabase";
import { Card } from "@tremor/react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";

export default function MessagesPage() {
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState("");

  useEffect(() => {
    getChats();
  }, []);

  async function getChats() {
    const { data, error } = await supabase.rpc("get_distinct_chat_ids");
    setChats(data);
  }
  useEffect(() => {
    supabase
      .from("tg_messages")
      .select("message")
      .eq("chat_id", selectedChat)
      .order("id", { ascending: false })
      .limit(100)
      .then((d) => setMessages(d.data.map((a) => a.message)));
  }, [selectedChat]);
  return (
    <div className="flex flex-row h-screen">
      <div className="w-72 overflow-y-auto border-r border-gray-200">
        {chats.map((c) => (
          <div
            key={c.chat_id}
            className={`mb-2 cursor-pointer p-4 border rounded ${
              c.chat_id === selectedChat
                ? "border-blue-500"
                : "border-transparent"
            }`}
            onClick={() => setSelectedChat(c.chat_id)}
          >
            <h2 className="font-bold">{c.title ?? c.username ?? c.chat_id}</h2>
          </div>
        ))}
      </div>
      <div className="mx-4" />
      <div className="flex-1 overflow-y-auto">
        {messages.map((m) => (
          <div key={m.id} className="mb-4 p-4 border rounded">
            <RRow horizontalAlign="space-between">
              <div className="text-gray-500 mb-1">
                {m.from.username ?? m.from.first_name}
              </div>
              <div className="text-gray-500 text-tremor-label mb-1">
                {new Date(m.date * 1000).toLocaleString()}
              </div>
            </RRow>
            <p className="text-gray-700 text-base break-words overflow-hidden overflow-ellipsis whitespace-normal">
              {m.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
