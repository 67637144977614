import { useEffect, useState } from "react";
import { db, getStatusFb } from "../firebase";
import { Badge, Button, Card, Switch } from "@tremor/react";
import { Tit2Tremor } from "../comps/coolComp";
import { RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { supabase } from "../supabase";
import { RiPencilLine } from "@remixicon/react";
import { doc, updateDoc } from "firebase/firestore";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../comps/errorMessagesComp";

export default function Status() {
  const [supaStatus, setSupaStatus] = useState([]);
  const [fbStatus, setFbStatus] = useState();

  const [modAbilit, setModAbilit] = useState(false);

  const fbKeys = [
    { key: "active", tit: "Platform open" },
    { key: "points", tit: "Points" },
    { key: "pvp", tit: "PVP" },
    { key: "p2e", tit: "P2E" },
    { key: "f2p", tit: "F2P" },
    { key: "withdrawal", tit: "Withdraws" },
    { key: "evenbet", tit: "Poker" },
    { key: "qtcasino", tit: "Casino" },
  ];

  useEffect(() => {
    load();
  }, []);

  function load() {
    getStatusFb().then((p) => setFbStatus(p));
    supabase
      .from("status")
      .select("*")
      .then((d) => setSupaStatus(d.data));
  }

  function SingleStat({ tit, active, fromFb, k }) {
    const [newState, setNewState] = useState(active);
    const [loading, setLoading] = useState(false);

    async function saveNewInfo() {
      setLoading(true);
      if (fromFb) {
        const ref = doc(db, "aBase", "status");
        try {
          await updateDoc(ref, { [k]: newState });
          setLoading(false);
          showSuccessNotification(
            tit + " to " + (newState ? "Active" : "Stopped")
          );
          load();
        } catch (e) {
          setLoading(false);
          console.log(e);
          showErrorNotification("Error");
        }
        return;
      }
      const { error } = await supabase
        .from("status")
        .update({ value: newState })
        .eq("id", k);

      if (error) {
        showErrorNotification("Error");
      } else {
        showSuccessNotification(
          tit + " to " + (newState ? "Active" : "Stopped")
        );
      }
      load();
      setLoading(false);
    }

    return (
      <Card>
        <RRow>
          <div className="w-28">
            <Tit2Tremor>{tit}</Tit2Tremor>
          </div>
          <Badge color={active ? "green" : "red"}>
            {active ? "Active" : "Stopped"}
          </Badge>
          {modAbilit && (
            <>
              <SpaceHorizontal />
              <Switch
                checked={newState}
                onClick={() => setNewState((p) => !p)}
              />
              <SpaceHorizontal />
              {newState !== active && (
                <Button loading={loading} onClick={saveNewInfo}>
                  Save
                </Button>
              )}
            </>
          )}
        </RRow>
      </Card>
    );
  }

  if (!fbStatus || supaStatus.length === 0) {
    return <>loading</>;
  }

  return (
    <>
      <Space />
      <RRow>
        <Tit2Tremor>Modify mode</Tit2Tremor>
        <SpaceHorizontal />
        <Switch checked={modAbilit} onClick={() => setModAbilit((p) => !p)} />
      </RRow>
      <Space />
      <div className="gap-2 flex flex-col">
        {fbKeys.map((v) => (
          <SingleStat
            tit={v.tit}
            active={fbStatus[v.key]}
            k={v.key}
            key={v.key}
            fromFb={true}
          />
        ))}
        {supaStatus.map((v) => (
          <SingleStat
            tit={v.tit}
            active={v.value}
            key={v.id}
            k={v.id}
            fromFb={false}
          />
        ))}
      </div>
    </>
  );
}
