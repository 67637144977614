import { ToastContainer } from "react-toastify";
import { ColorPalette } from "./util/const";
import { RColumn, RRow, Space, SpaceHorizontal } from "./comps/otherCOmps";
import { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { SelectTremor } from "./comps/coolComp";
import { Callout, Icon } from "@tremor/react";
import {
  Ri4kFill,
  RiAlarmWarningLine,
  RiArrowLeftLine,
  RiBuilding2Line,
  RiFile2Line,
  RiGamepadFill,
  RiGamepadLine,
  RiGroupLine,
  RiLineChartLine,
  RiMessageLine,
  RiMoneyDollarBoxLine,
  RiNewsLine,
  RiSettings2Line,
  RiStickyNoteLine,
  RiToolsLine,
  RiUserSharedLine,
} from "@remixicon/react";
import SettingsPage from "./settings/settingsPage";
import DashboardPage from "./dashboard/dashboardPage";
import ReferralPage from "./referrals/refPage";
import TaskPage from "./task/taskPage";
import LogsPage from "./logs/logsPage";
import SendMsgsPage from "./sendmsg/sendMsgPage";
import P2EPage from "./p2e/p2e";
import WithdrawalsPage from "./withdrawals/withdrawals";
import ChatGames from "./chatgames/page";
import GroupsPage from "./groups/page";
import BestChats from "./chatgames/bestChats";
import LatestBetsChatGames from "./chatgames/latestBets";
import MessagesPage from "./messages/page";
import UnfinishedGames from "./chatgames/unfinished";
import Status from "./status/page";

export default function PagesContainer() {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  function SingleBtn({ tit, href, icon = Ri4kFill }) {
    return (
      <NavLink
        style={{ width: "100%" }}
        to={href}
        className={({ isActive }) =>
          isActive ? "active-link-sidebar" : "inactive-link-sidebar"
        }
      >
        <RRow width={"100%"} horizontalAlign="start">
          <Icon icon={icon} variant="simple" color="black" />
          {isMenuOpen && <SpaceHorizontal />}
          {isMenuOpen ? tit : null}
        </RRow>
      </NavLink>
    );
  }
  function SingleBtnSub({ tit, href }) {
    return (
      <NavLink
        style={{ width: "100%", paddingLeft: isMenuOpen ? "44px" : "12px" }}
        to={href}
        className={({ isActive }) =>
          isActive ? "active-link-sidebar" : "inactive-link-sidebar2"
        }
      >
        {isMenuOpen && <SpaceHorizontal />}
        {isMenuOpen ? tit : tit.slice(0, 1)}
      </NavLink>
    );
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <div
        style={{
          overflowY: "auto",
          zIndex: 10,
          position: "fixed",
          width: isMenuOpen ? "260px" : "80px",
          height: "100vh",
          backgroundColor: ColorPalette.bgColorLight,
          top: 0,
          left: 0,
        }}
        className="animate"
      >
        <Space height="12px" />
        <RColumn
          verticalAlignment="space-between"
          height={"95%"}
          padding={isMenuOpen ? "0px 22px" : null}
        >
          <RColumn
            width={"100%"}
            horizontalAlign={isMenuOpen ? "start" : "center"}
            verticalAlignment="start"
          >
            <RColumn>
              <div style={{ height: "100px" }}>
                {!isMenuOpen && <Space height="18px" />}
                <img
                  src={"/images/logo_low.png"}
                  width={isMenuOpen ? "70px" : "32px"}
                />
              </div>
              <SingleBtn tit={"Dashboard"} href={"/"} icon={RiLineChartLine} />
              <SingleBtn
                tit={"Referrals"}
                href={"/referrals"}
                icon={RiUserSharedLine}
              />

              <SingleBtn tit={"Task"} href={"/task"} icon={RiStickyNoteLine} />
              <SingleBtn tit={"Logs"} href={"/logs"} icon={RiNewsLine} />
              <SingleBtn tit={"P2E"} href={"/p2e"} icon={RiGamepadLine} />
              <SingleBtn tit={"Messages"} href={"/msgs"} icon={RiMessageLine} />
              {/*  <SingleBtn
                tit={"Withdrawals"}
                href={"/withdrawals"}
                icon={RiMoneyDollarBoxLine}
              /> */}
              <SingleBtn
                tit={"Chat games"}
                href={"/chatgames"}
                icon={RiGamepadFill}
              />
              <SingleBtnSub tit={"Best chats"} href={"/chatgames/bestchat"} />
              <SingleBtnSub tit={"Last bets"} href={"/chatgames/latest"} />
              <SingleBtnSub tit={"Unfinished"} href={"/chatgames/unfinished"} />
              <SingleBtn
                tit={"Groups"}
                href={"/groups"}
                icon={RiUserSharedLine}
              />
              <SingleBtn
                tit={"Group msg"}
                href={"/messages"}
                icon={RiMessageLine}
              />
              <SingleBtn
                tit={"Status"}
                href={"/status"}
                icon={RiAlarmWarningLine}
              />
            </RColumn>
          </RColumn>

          <RRow
            width={"100%"}
            horizontalAlign={!isMenuOpen ? "center" : "start"}
          >
            <RColumn horizontalAlign="start">
              <SingleBtn
                tit={"Settings"}
                href={"/settings"}
                icon={RiSettings2Line}
              />
              <Space />
              <div style={{ cursor: "pointer" }}>
                <RiArrowLeftLine
                  className="animate"
                  style={{ rotate: isMenuOpen ? "0deg" : "180deg" }}
                  onClick={() => setIsMenuOpen((prev) => !prev)}
                />
              </div>
            </RColumn>
          </RRow>
        </RColumn>
        <Space />
      </div>
      <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <div
          className="animate"
          style={{ width: isMenuOpen ? "270px" : "100px" }}
        ></div>
        <div style={{ flex: 1 }}>
          <div style={{ padding: "12px 18px" }}>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route exact path="/referrals" element={<ReferralPage />} />
              <Route exact path="/task" element={<TaskPage />} />
              <Route exact path="/logs" element={<LogsPage />} />
              <Route exact path="/settings" element={<SettingsPage />} />
              <Route exact path="/msgs" element={<SendMsgsPage />} />
              <Route exact path="/withdrawals" element={<WithdrawalsPage />} />
              <Route exact path="/p2e" element={<P2EPage />} />
              <Route exact path="/messages" element={<MessagesPage />} />
              <Route exact path="/status" element={<Status />} />
              <Route exact path="/chatgames" element={<ChatGames />} />

              <Route exact path="/chatgames/bestchat" element={<BestChats />} />
              <Route
                exact
                path="/chatgames/unfinished"
                element={<UnfinishedGames />}
              />
              <Route
                exact
                path="/chatgames/latest"
                element={<LatestBetsChatGames />}
              />
              <Route exact path="/groups" element={<GroupsPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
