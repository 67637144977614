import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import {
  RColumn,
  RGrid,
  RRow,
  Space,
  SpaceHorizontal,
} from "../comps/otherCOmps";
import {
  AreaChart,
  Badge,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import {
  cancelGame,
  getBestGamesChatGames,
  getBestGroupsChatGames,
  getLastChatGames,
  getStatsChatGames,
} from "./dao";
import { formatNumberInStr, getPriceToken } from "../util/util";
import {
  RiCircleFill,
  RiCircleLine,
  RiDoubanFill,
  RiIdCardFill,
} from "@remixicon/react";

export default function LatestBetsChatGames() {
  const [lastGames, setLastGames] = useState([]);

  useEffect(() => {
    getLastChatGames(30).then((p) => setLastGames(p));
  }, []);
  return (
    <>
      <Tit2Tremor>Last bets</Tit2Tremor>
      <Space />
      {lastGames.map((g, i) => (
        <Card key={g.id + i} className="mb-3">
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            {g.timestamp.substring(0, 19).replace("T", " ")}
          </dt>
          <RRow width={"100%"} horizontalAlign="space-between">
            <dt className="text-tremor-default font-medium dark:text-dark-tremor-content">
              {g.game_type}
            </dt>
            <RRow>
              <Badge>{g.status}</Badge>
              <Badge>{g.result}</Badge>
            </RRow>
          </RRow>
          <Space height="6px" />
          <RRow>
            <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              Amount per usr: {g.amount_per_user.substring(0, 6)}{" "}
              {g.currency.toUpperCase()}
            </p>
            <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              Total payout: {g.total_payout.substring(0, 6)}{" "}
              {g.currency.toUpperCase()}
            </p>
          </RRow>
          <Space height="6px" />
          <RRow horizontalAlign="space-between">
            <RColumn horizontalAlign="start">
              <Tit2Tremor>Players</Tit2Tremor>
              {g.users.map((u) => (
                <DescTremor key={u + "p"}>{u}</DescTremor>
              ))}
            </RColumn>
            <RColumn horizontalAlign="start">
              <Tit2Tremor>Winners</Tit2Tremor>
              {g.winners.map((u) => (
                <DescTremor key={u + "w"}>{u}</DescTremor>
              ))}
            </RColumn>
          </RRow>
        </Card>
      ))}
    </>
  );
}
