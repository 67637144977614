export class ColorPalette {
  static primaryColor = "red";
  static accentOrange = "red";
  static accentGreen = "red";

  static bgColorDark = "#0D0D0D";
  static containerColorDark = "#1A1A1A";
  static containerSecondaryColorDark = "#292929";
  static textColorDark = "white";
  static textSecondaryDark = "#CBCACA";

  static bgColorLight = "#F4F4F4";
  static containerColorLight = "#E3E3E3";
  static containerSecondaryColorLight = "#F4F4F4";
  static textColorLight = "#000000";
  static textSecondaryLight = "#121212";

  static okGreenColor = "#22c55e";
  static errorRedColor = "#ef4444";
}

export const loadingState = {
  LOADING: "loading",
  ERROR: "error",
  FINISH: "finish",
  START: "start",
};

export class BaseData {
  static baseInfo = null;
}
