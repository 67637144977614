import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import UserSettingsTab from "./userSettings";

export default function SettingsPage() {
  return (
    <>
      <TabGroup>
        <TabList>
          <Tab>User</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserSettingsTab />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}
