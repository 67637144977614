import "./comps.css";
import "../App.css";
import { ColorPalette } from "../util/const";
import React, { useState } from "react";
import Select from "react-select";

export function Space({ height = "20px" }) {
  return <div style={{ height: height }}></div>;
}
export function SpaceHorizontal({ width = "10px" }) {
  return <div style={{ width: width }}></div>;
}
export const SecondaryBtn = ({ children, onClick }) => {
  return (
    <div
      className="secondary-button"
      style={{ color: "white" }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export const BlackButton = ({ children, onClick }) => {
  return (
    <div className="black-button" style={{ color: "white" }} onClick={onClick}>
      {children}
    </div>
  );
};

export function PrimaryBtn({ children, _width = null, onClick }) {
  return (
    <button
      style={{
        cursor: "pointer",
        backgroundColor: ColorPalette.accentOrange,
        color: "white",
        border: "none",
        padding: "12px 32px",
        fontWeight: "bold",
        width: _width,
        borderRadius: "40px",
        fontSize: "18px",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
export function TerzButton({ children, _width = null, onClick }) {
  return (
    <button className="terz-button" style={{ width: _width }} onClick={onClick}>
      {children}
    </button>
  );
}

export function RColumn({
  minWidth = null,
  children,
  horizontalAlign = "center",
  verticalAlignment = "start",
  height = null,
  width = null,
  bgColor,
  padding = null,
  flex = null,
}) {
  const style = {
    height: height,
    display: "flex",
    alignItems: horizontalAlign,
    justifyContent: verticalAlignment,
    flexDirection: "column",
    width: width,
    backgroundColor: bgColor,
    padding: padding,
    minWidth: minWidth,
    flex: flex,
  };
  return <div style={style}>{children}</div>;
}

export function RPadding({ children, padding }) {
  return <div style={{ padding: padding }}>{children}</div>;
}
export function RRow({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  minHeight = null,
  padding = null,
  bgColor = null,
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexDirection: "row",
    backgroundColor: bgColor,
    padding: padding,
    minHeight: minHeight,
  };
  return <div style={style}>{children}</div>;
}
export function RGrid({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  minHeight = null,
  padding = null,
  bgColor = null,
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexWrap: "wrap",
    backgroundColor: bgColor,
    padding: padding,
    minHeight: minHeight,
  };
  return <div style={style}>{children}</div>;
}

export function RText({
  children,
  align = null,
  size = "18px",
  color = null,
  fontWeight = "normal",
}) {
  const dColor = ColorPalette.textSecondaryLight;
  return (
    <div
      style={{
        color: color ?? dColor,
        fontSize: size,
        fontWeight: "normal",
        textAlign: align,
        fontWeight: fontWeight,
      }}
    >
      {children}
    </div>
  );
}

export function Title1({ children, align = null, color = null }) {
  return (
    <div
      style={{
        color: color ?? "black",
        fontSize: "32px",
        fontWeight: 900,
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}
export function Title2({ children, align = null, size = "24px" }) {
  return (
    <div
      style={{
        color: "black",
        fontSize: size,
        fontWeight: "bold",
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}
export function SubTitle1({ children, align = null }) {
  return (
    <div
      style={{
        color: ColorPalette.textSecondaryLight,
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}

export const customStylesDropMenu = (width, isDarkMode) => {
  return {
    control: (base, state) => ({
      ...base,
      width: width,
      margin: "8px 0px",
      background: isDarkMode
        ? ColorPalette.containerSecondaryColorDark
        : ColorPalette.containerSecondaryColorLight,
      cursor: "pointer",
      borderRadius: "10px",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      fontWeight: "normal",
      padding: "6px 14px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected
        ? ColorPalette.accentOrange
        : ColorPalette.bgColorLight,
      cursor: "pointer",
      backgroundColor: "red",
      fontWeight: "normal",
      "&:hover": {
        backgroundColor: "#e0835c",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "normal",
      color: isDarkMode
        ? ColorPalette.textSecondaryDark
        : ColorPalette.textSecondaryLight,
    }),
  };
};
export function DropDownMenu({
  options,
  selectedValue,
  onChange,
  placeHolder,
  isMulti,
  siDisabled,
  width = "500px",
}) {
  return (
    <Select
      isDisabled={siDisabled}
      isMulti={isMulti}
      options={options}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeHolder}
      styles={customStylesDropMenu(width, false)}
    />
  );
}
