import {
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { getReferralLead } from "../firebase";

export default function RefUsersLead() {
  const [refLead, setRefLead] = useState([]);

  useEffect(() => {
    getReferralLead().then((lead) => setRefLead(lead));
  }, []);

  return (
    <>
      <Table className="mt-8">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Username
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              num Ref
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refLead.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>{item.username}</TableCell>
              <TableCell>{item.numReferrals}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
