import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { db, functions, getTotTgIdsCount } from "../firebase";
import { Badge, Button, Card, Textarea } from "@tremor/react";
import {
  RColumn,
  RGrid,
  RRow,
  Space,
  SpaceHorizontal,
} from "../comps/otherCOmps";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { Ri24HoursFill, RiLoader4Line } from "@remixicon/react";
import { getTotalUsersCount } from "../supaDAO";

export default function SendMsgsPage() {
  const [msgState, setMsgState] = useState("init");
  const [msg, setMsg] = useState({ body: "", ctaTxt: null });
  const [usersCount, setUsersCount] = useState(0);
  const [msgRes, setMsgRes] = useState({ sended: 0, errors: 0 });

  const [lastMessages, setLastMessages] = useState([]);
  useEffect(() => {
    getTotalUsersCount().then((c) => setUsersCount(c));

    getDocs(
      query(collection(db, "mass-messages"), orderBy("date", "desc"), limit(10))
    ).then((ds) =>
      setLastMessages(ds.docs.map((d) => ({ ...d.data(), id: d.id })))
    );
  }, []);

  async function sendMsg(toAll = false) {
    setMsgState("loading");

    try {
      const funct = httpsCallable(functions, "sendMsgToAllUsers");

      const r = await funct({
        passw: "53t3gdfr3",
        body: msg.body,
        cta_txt: msg.ctaTxt ?? null,
        onlyTo: toAll ? null : "706042120",
        maxUsers: 5000,
        fromN: 30000, //dopo da 25000
        toN: 32000,
      });
      setMsgRes(r.data);
      console.log(r.data);
      setMsgState("init");
    } catch (e) {
      console.log(e);
      setMsgState("error");
    }
  }

  return (
    <>
      <Space />
      <RGrid verticalAlignment="start" horizontalAlign="space-between">
        {/*     <RColumn horizontalAlign="start" width={"49%"}>
          <h2>Send message to all users</h2>
          <Space />
          <Textarea
            onChange={(e) => setMsg((p) => ({ ...p, body: e.target.value }))}
            placeholder="Message body"
          />

          <Space />
          <Textarea
            placeholder="Button text"
            onChange={(e) => setMsg((p) => ({ ...p, ctaTxt: e.target.value }))}
          />
          <Space />
          <h1>{usersCount} total users to send</h1>
          <Space />
          <RRow>
            <Button
              loading={msgState === "loading"}
              onClick={() => sendMsg(false)}
            >
              Test Send
            </Button>
            <SpaceHorizontal />
            <Button
              loading={msgState === "loading"}
              onClick={() => sendMsg(true)}
            >
              Send to all
            </Button>
          </RRow>
          <Space />

          <p>Total success: {msgRes.sended}</p>
          <p>Total errors: {msgRes.errors}</p>
        </RColumn> */}
        <RColumn horizontalAlign="start" width={"49%"}>
          <h2>Last messages</h2>
          <Space />
          {lastMessages.map((mes) => (
            <Card key={mes.id} className="mb-3 p-4">
              {mes.state === "sending" && (
                <RRow>
                  <RiLoader4Line className="animate-spin" size={20} />
                  Sending
                </RRow>
              )}
              <RRow horizontalAlign="space-between">
                <RRow>
                  <Badge color={"green"}>{mes.sended} received</Badge>
                  <SpaceHorizontal />
                  <Badge color={"red"}>{mes.errors} errors</Badge>
                </RRow>
                <DescTremor>
                  {mes.date.slice(0, 16).replace("T", " ")}
                </DescTremor>
              </RRow>

              <Space />
              <p className="text-gray-500 text-sm">
                {mes.message.slice(0, 100)}...
              </p>
            </Card>
          ))}
        </RColumn>
      </RGrid>
    </>
  );
}
