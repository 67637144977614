import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { getBestGroupsChatGames, getBestPlayersChatGames } from "./dao";
import {
  RColumn,
  RGrid,
  RRow,
  Space,
  SpaceHorizontal,
} from "../comps/otherCOmps";
import { Card } from "@tremor/react";
import { formatNumberInStr } from "../util/util";

export default function BestChats() {
  const [bestChats, setBestChats] = useState([]);
  const [bestPlayers, setBestPlayers] = useState([]);

  useEffect(() => {
    getBestGroupsChatGames().then((p) => setBestChats(p));
    getBestPlayersChatGames().then((p) => setBestPlayers(p));
  }, []);
  return (
    <>
      <Tit2Tremor>Best chats</Tit2Tremor>
      <Space />
      <DescTremor>
        Profit are mean for the user/chat, so a positve porfit for them is a
        loss for us
      </DescTremor>
      <Space />
      <RGrid verticalAlignment="start">
        <RColumn>
          <Tit2Tremor>Chat (Grops or bot dm)</Tit2Tremor>
          {bestChats.map((b) => (
            <Card key={b.chat_id} className="mt-3 w-96">
              <dd className="mt-2 flex items-stretch space-x-2.5 w-full justify-between">
                <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  {b.name}
                </span>
                <dt className="text-tremor-label dark:text-dark-tremor-content">
                  {b.chat_id}
                </dt>
              </dd>
              <dd className="mt-2 flex items-stretch space-x-2.5 w-full">
                <dd className="mt-2 flex items-end space-x-2.5 justify-between">
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {formatNumberInStr(b.totalBets)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    bets
                  </span>
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(b.totalVolume)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    volume
                  </span>
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(b.totalProfit, true)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    profit
                  </span>
                </dd>
              </dd>
            </Card>
          ))}
        </RColumn>
        <SpaceHorizontal />
        <RColumn>
          <Tit2Tremor>Single user</Tit2Tremor>
          {bestPlayers.map((b) => (
            <Card key={b.user_id} className="mt-3 w-96">
              <dd className="mt-2 flex items-stretch space-x-2.5 w-full justify-between">
                <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  {b.name}
                </span>
                <dt className="text-tremor-label dark:text-dark-tremor-content">
                  {b.user_id}
                </dt>
              </dd>
              <dd className="mt-2 flex items-stretch space-x-2.5 w-full">
                <dd className="mt-2 flex items-end space-x-2.5 justify-between">
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {formatNumberInStr(b.totalBets)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    bets
                  </span>
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(b.totalVolume)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    volume
                  </span>
                  <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(b.totalProfit)}
                  </span>
                  <span className="text-tremor-label text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    profit
                  </span>
                </dd>
              </dd>
            </Card>
          ))}
        </RColumn>
      </RGrid>
    </>
  );
}
