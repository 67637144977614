import {
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { getReferralLead, getReferralPartnerLead } from "../firebase";
import { RiFileCopyLine } from "@remixicon/react";
import { RRow, SpaceHorizontal } from "../comps/otherCOmps";
import { copyString } from "../util/util";

export default function PartenerLead() {
  const [refLead, setRefLead] = useState([]);

  useEffect(() => {
    getReferralPartnerLead().then((lead) => setRefLead(lead));
  }, []);

  return (
    <>
      <Table className="mt-8">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Name
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              num Ref
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Link
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refLead.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>
                {item.partner_name !== "" ? item.partner_name : item.code}
              </TableCell>
              <TableCell>{item.numReferrals}</TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyString(
                    `https://t.me/TheGameHubBot/play?startapp=ref=${item.code}`
                  )
                }
              >
                <RRow>
                  <RiFileCopyLine size={16} />
                  <SpaceHorizontal width="4px" />
                  https://t.me/TheGameHubBot/play?startapp=ref={item.code}
                </RRow>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
