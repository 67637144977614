import { useState } from "react";
import {
  InputField,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  Space,
  Title1,
  Title2,
} from "./comps/otherCOmps";
import { ColorPalette } from "./util/const";
import { auth, db, logOut, login, verifyAccount } from "./firebase";
import { showErrorNotification } from "./comps/errorMessagesComp";
import { doc, getDoc } from "firebase/firestore";
import { Button, Divider, TextInput } from "@tremor/react";
import { ToastContainer } from "react-toastify";
import { supabase } from "./supabase";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    loginF(email, password);
  };

  const loginF = async (email, password) => {
    setIsLoading(true);
    try {
      await login(email, password);

      const { error: supabaseError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (supabaseError) {
        throw new Error(`Supabase: ${supabaseError.message}`);
      }
    } catch (e) {
      showErrorNotification("Wrong email or password");
      await logOut();
      await supabase.auth.signOut();
    }
    setIsLoading(false);
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <div className="flex min-h-full flex-1 flex-col justify-center px-4 py-10 lg:px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Space />
          <h3 className="text-center text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Log in
          </h3>
          <form onSubmit={(e) => handleSubmit(e)} className="mt-6">
            <label
              htmlFor="email"
              className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
            >
              Email
            </label>
            <TextInput
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              placeholder="e-mail"
              className="mt-2"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              htmlFor="passw"
              className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
            >
              Password
            </label>
            <TextInput
              type="password"
              id="passw"
              name="email"
              autoComplete="password"
              placeholder="password"
              className="mt-2"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              className="mt-4 w-full whitespace-nowrap rounded-tremor-default bg-tremor-brand py-2 text-center text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
            >
              Log in
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
