import { useEffect, useState } from "react";
import { Tit2Tremor } from "../comps/coolComp";
import { functions, getPointsInfo, getStats, getTotWallets } from "../firebase";
import { Button, Card, DonutChart, List, ListItem } from "@tremor/react";
import { Space } from "../comps/otherCOmps";
import { classNames, formatNumberInStr } from "../util/util";
import { httpsCallable } from "firebase/functions";
import { getTotChatGames } from "../chatgames/dao";

export default function DashboardPage() {
  const [stats, setStats] = useState();

  const [pointsSummary, setPointsSummary] = useState({});

  const [userBalances, setUserbalances] = useState([]);
  const [donutDataprofit, setDonutDataProfit] = useState([]);
  const [donutDataVolume, setDonutDataVolume] = useState([]);

  const [topData, setTopData] = useState([
    { name: "Total earnings", stat: 0, change: 0, changeType: "positive" },
    { name: "PVP volume", stat: 0, change: 0, changeType: "positive" },
    { name: "Total games wins", stat: 0, change: 0, changeType: "positive" },
    { name: "Total P2E earnings", stat: 0, change: 0, changeType: "positive" },
  ]);
  useEffect(() => {
    getStats().then(async (s) => {
      setStats(s);
      const chatgamesstats = await getTotChatGames();
      setDonutDataProfit(
        [
          {
            name: "PVP",
            amount: s.total_earnings,
            color: "red",
          },
          {
            name: "P2E",
            amount: s.p2e_attemps_earnings,
            color: "blue",
          },
          {
            name: "Chat Games",
            amount: chatgamesstats.totalProfit,
            color: "violet",
          },
        ].sort((a, b) => b.amount - a.amount)
      );
      setDonutDataVolume(
        [
          {
            name: "PVP",
            amount: s.total_volume,
            color: "red",
          },
          {
            name: "Chat Games",
            amount: chatgamesstats.totalVolume,
            color: "violet",
          },
        ].sort((a, b) => b.amount - a.amount)
      );
    });
    getPointsInfo().then((p) => setPointsSummary(p));
    getTotWallets().then((w) => setUserbalances(w));
  }, []);

  useEffect(() => {
    if (!stats) return;
    setTopData([
      {
        name: "Total earnings",
        stat: "$" + formatNumberInStr(stats.total_earnings),
        change: 0,
        changeType: "positive",
      },
      {
        name: "PVP volume",
        stat: "$" + formatNumberInStr(stats.total_volume),
        change: 0,
        changeType: "positive",
      },
      {
        name: "Total games wins",
        stat: formatNumberInStr(stats.total_games_win),
        change: 0,
        changeType: "positive",
      },
      {
        name: "Total P2E earnings",
        stat: "$" + formatNumberInStr(stats.p2e_attemps_earnings),
        change: 0,
        changeType: "positive",
      },
    ]);
  }, [stats]);
  return (
    <>
      <Tit2Tremor>Dashboard</Tit2Tremor>
      <Space />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <Card className="min-w-52 sm:max-w-lg mx-auto">
          <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Total profits
          </h3>
          <DonutChart
            className="mt-8"
            data={donutDataprofit}
            category="amount"
            index="name"
            valueFormatter={(s) => {
              return "$" + formatNumberInStr(s);
            }}
            showTooltip={true}
          />
          <p className="mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            <span>Category</span>
            <span>Amount</span>
          </p>
          <List className="mt-2">
            {donutDataprofit.map((item) => (
              <ListItem key={item.name} className="space-x-6">
                <div className="flex items-center space-x-2.5 truncate">
                  <span
                    className={classNames(
                      item.color,
                      "size-2.5 shrink-0 rounded-sm"
                    )}
                    aria-hidden={true}
                  />
                  <span className="truncate dark:text-dark-tremor-content-emphasis">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(item.amount, true)}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
        <Card className="min-w-52 sm:max-w-lg mx-auto">
          <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Total volume
          </h3>
          <DonutChart
            className="mt-8"
            data={donutDataVolume}
            category="amount"
            index="name"
            valueFormatter={(s) => {
              return "$" + formatNumberInStr(s);
            }}
            showTooltip={true}
          />
          <p className="mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            <span>Category</span>
            <span>Amount</span>
          </p>
          <List className="mt-2">
            {donutDataVolume.map((item) => (
              <ListItem key={item.name} className="space-x-6">
                <div className="flex items-center space-x-2.5 truncate">
                  <span
                    className={classNames(
                      item.color,
                      "size-2.5 shrink-0 rounded-sm"
                    )}
                    aria-hidden={true}
                  />
                  <span className="truncate dark:text-dark-tremor-content-emphasis">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    ${formatNumberInStr(item.amount, true)}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
      <Space />
      {/*  <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {topData.map((item) => (
          <Card key={item.name}>
            <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              {item.name}
            </dt>
            <dd className="mt-2 flex items-baseline space-x-2.5">
              <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {item.stat}
              </span>
            </dd>
          </Card>
        ))}
      </dl>
      <Space /> */}
      User balances
      <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {userBalances.map((token) => (
          <Card key={token.label}>
            <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              {token.label}
            </dt>
            <dd className="mt-2 flex items-baseline space-x-2.5">
              <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                ${formatNumberInStr(token.usd, true)}
              </span>
              <dt className="font-medium text-tremor-content dark:text-dark-tremor-content">
                {formatNumberInStr(token.amount)} {token.symbol}
              </dt>
            </dd>
          </Card>
        ))}
      </dl>
      <Space />
      Points
      <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points given
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_now)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points ads
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_ads)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points partner
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_partner)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points social media
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_social_media)}
            </span>
          </dd>
        </Card>
      </dl>
    </>
  );
}
