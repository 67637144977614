import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { auth, db } from "../firebase";
import axios from "axios";
import { getPriceToken } from "../util/util";

const ref = collection(db, "games-inline");

export async function getLastChatGames(num = 5) {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/lastbets?num=${num}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}

export async function getStatsChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/statsgames`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);

    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}

export async function getTotChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/totstats`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}

export async function getBestGamesChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/bestgames`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}

export async function getBestGroupsChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/bestchats`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    const a = response.data.sort((a, b) => {
      const profitA = a.totalProfit ?? 0;
      const profitB = b.totalProfit ?? 0;
      return profitB - profitA;
    });
    return a;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}
export async function getBestPlayersChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/bestusers`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    const a = response.data.sort((a, b) => {
      const profitA = a.totalProfit ?? 0;
      const profitB = b.totalProfit ?? 0;
      return profitB - profitA;
    });
    return a;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}
export async function cancelGameChatGames(gameId, betId) {
  const idToken = await auth.currentUser.getIdToken();
  await axios.post(
    `https://uno.thegamehub.gg:3050/chatgames/cancelgame/${gameId}?betId=${betId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
}
export async function getUnfinishedChatGames() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/unfinishedGames`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}
