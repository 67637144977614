import { useEffect, useState } from "react";
import {
  RColumn,
  RGrid,
  RRow,
  Space,
  SpaceHorizontal,
} from "../comps/otherCOmps";
import {
  functions,
  getPendingWithdrawals,
  getWaitingCodeWith,
  getWaitingManualWith,
  getWaitingSubmitWith,
} from "../firebase";
import { Badge, Button, Card, Textarea } from "@tremor/react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { formatNumberInStr } from "../util/util";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../comps/errorMessagesComp";

export default function WithdrawalsPage() {
  const [waitingManual, setWaitingManual] = useState([]);
  const [waitingCode, setWaitingCode] = useState([]);

  const [waitingProcess, setWaitingProcess] = useState([]);
  const [stateWithds, setStateWidths] = useState({});

  const [processState, setProcessState] = useState("init");

  const [verifCode, setVerifCode] = useState({
    batch_id: "",
    verification_code: "",
    state: "init",
  });

  useEffect(() => {
    reloadData();
  }, []);

  function reloadData() {
    getWaitingManualWith().then((p) => setWaitingManual(p));
    getWaitingSubmitWith().then((p) => setWaitingProcess(p));
    getWaitingCodeWith().then((p) => setWaitingCode(p));
  }

  async function confirmManual(id) {
    const funct = httpsCallable(functions, "confirmManualWit");
    try {
      setStateWidths((p) => ({ ...p, [id]: "loading" }));
      const res = await funct({ withId: id });
      console.log(res);
      if (!res.data.success) throw "Error";
      setStateWidths((p) => ({ ...p, [id]: "finish" }));
      reloadData();
    } catch (error) {
      console.log(error);
      showErrorNotification("Error");
      setStateWidths((p) => ({ ...p, [id]: "error" }));
    }
  }

  async function processWith() {
    setProcessState("loading");
    try {
      const funct = httpsCallable(functions, "processWithdrawal");
      const res = await funct();
      console.log(res.data);
      if (res.data.success === false) throw "Error";
      setVerifCode((p) => ({ ...p, batch_id: res.data.batch_withdrawal_id }));
      setProcessState("finished");
      reloadData();
    } catch (error) {
      console.log(error);
      showErrorNotification("Error");
      setProcessState("error");
    }
  }

  async function verifyWithCode() {
    setVerifCode((p) => ({ ...p, state: "loading" }));
    const funct = httpsCallable(functions, "verifyAndSumbitWithdrawal");
    try {
      const res = await funct({
        verification_code: verifCode.verification_code,
        batch_id: verifCode.batch_id,
      });
      setVerifCode((p) => ({ ...p, state: "init" }));
      reloadData();
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setVerifCode((p) => ({ ...p, state: "error" }));

      showErrorNotification("Error");
    }
  }

  function SingleWith({ data }) {
    return (
      <Card key={data.id}>
        <RRow horizontalAlign="space-between">
          <RColumn horizontalAlign="start">
            <DescTremor>{data.uid}</DescTremor>

            <RRow>
              <Tit2Tremor>
                {data.amount} {data.coin}
              </Tit2Tremor>
              <SpaceHorizontal />
              <Tit2Tremor>${formatNumberInStr(data.usdAmount)}</Tit2Tremor>
            </RRow>
          </RColumn>
          {data.needManual && (
            <Button
              loading={stateWithds[data.id] === "loading"}
              onClick={() => confirmManual(data.id)}
            >
              Confirm
            </Button>
          )}
        </RRow>
        <p>batchID: {data.batch_withdrawal_id}</p>
      </Card>
    );
  }
  return (
    <>
      <RGrid verticalAlignment="start" horizontalAlign="space-between">
        <RColumn>
          <Tit2Tremor>Pending manual</Tit2Tremor>
          <Space />
          {waitingManual.map((w) => (
            <SingleWith data={w} />
          ))}
        </RColumn>
        <SpaceHorizontal />
        <RColumn>
          <Tit2Tremor>Pending process</Tit2Tremor>
          <Space />
          {waitingProcess.map((w) => (
            <SingleWith data={w} />
          ))}
        </RColumn>
        <SpaceHorizontal />
        <RColumn>
          <Tit2Tremor>Process withds</Tit2Tremor>
          <Space />
          {waitingProcess.length > 0 ? (
            <Button loading={processState === "loading"} onClick={processWith}>
              Add in NP queqe
            </Button>
          ) : (
            <p>No withdrawals are waiting for</p>
          )}
        </RColumn>
        <RColumn>
          <Tit2Tremor>Pending code</Tit2Tremor>
          <Space />
          {waitingCode.map((w) => (
            <SingleWith data={w} />
          ))}
        </RColumn>
        <RColumn>
          <Tit2Tremor>Verify with code</Tit2Tremor>
          <Space />
          <Textarea
            placeholder="Batch ID"
            value={verifCode.batch_id ?? ""}
            onChange={(e) =>
              setVerifCode((p) => ({ ...p, batch_id: e.target.value }))
            }
          />
          <Textarea
            placeholder="verification code"
            value={verifCode.verification_code ?? ""}
            onChange={(e) =>
              setVerifCode((p) => ({ ...p, verification_code: e.target.value }))
            }
          />
          <Space />
          <Button
            loading={verifCode.state === "loading"}
            onClick={verifyWithCode}
          >
            Verify and process
          </Button>
        </RColumn>
      </RGrid>
    </>
  );
}
