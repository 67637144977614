import { Button, Card } from "@tremor/react";
import { useEffect, useState } from "react";
import { addGroup, getLastGroups } from "./dao";
import { RRow, Space } from "../comps/otherCOmps";

export default function GroupsPage() {
  const [isAddingGroup, setIsAddingGroup] = useState(false);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getLastGroups().then((p) => setGroups(p));
  }, []);

  async function addG() {
    setIsAddingGroup(true);
    await addGroup();
    setIsAddingGroup(false);
    getLastGroups().then((p) => setGroups(p));
  }
  return (
    <>
      <Button onClick={addG} loading={isAddingGroup}>
        Add fake group
      </Button>
      <Space />
      {groups.map((g) => (
        <Card key={g.tgid} className="mb-3 w-80">
          <RRow horizontalAlign="space-between">
            <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              {g.tgid}
            </dt>
            <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              {g.added_at.slice(0, 19).replace("T", " ")}
            </dt>
          </RRow>

          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {g.name}
            </span>
          </dd>
        </Card>
      ))}
    </>
  );
}
