import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { getLogs } from "../firebase";
import {
  Badge,
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
export default function LogsPage() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getLogs().then((l) => setLogs(l));
  }, []);

  function LogData({ dat }) {
    const type = dat.type;

    if (type === "user_created") {
      return (
        <RRow>
          <DescTremor>username: @{dat.username}</DescTremor>
        </RRow>
      );
    }
    if (type === "referral_updated") {
      return (
        <RRow>
          <DescTremor>
            @{dat.userReferredName ?? dat.userReferreduid} by @
            {dat.userWhoReferredName !== ""
              ? dat.userWhoReferredName
              : dat.userWhoReferreduid}{" "}
            ({dat.tot_refs} total)
          </DescTremor>
        </RRow>
      );
    }
    if (type === "create_game_room") {
      return (
        <RRow>
          <DescTremor>price: ${dat.price}</DescTremor>
          <SpaceHorizontal />
          <DescTremor>token: {dat.token}</DescTremor>
          <SpaceHorizontal />
          <DescTremor>{dat.gameNameUrl}</DescTremor>
        </RRow>
      );
    }
    if (type === "win_game") {
      return (
        <RRow>
          <DescTremor>price: ${dat.gameData.price}</DescTremor>
          <SpaceHorizontal />
          <DescTremor>token: {dat.gameData.token}</DescTremor>
          <SpaceHorizontal />
          <DescTremor>{dat.gameData.gameUrl}</DescTremor>
          <SpaceHorizontal />
          <DescTremor>
            {dat.gameData.players[0].username} vs{" "}
            {dat.gameData.players[1].username}
          </DescTremor>
        </RRow>
      );
    }

    return <></>;
  }
  return (
    <>
      <Tit2Tremor>Logs</Tit2Tremor>
      <Space />
      <Table className="mt-8 w-full">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Type
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Date
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Data
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Status
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>{item.type}</TableCell>
              <TableCell>
                {new Date(item.date)
                  .toISOString()
                  .substring(0, 19)
                  .replace("T", " ")}
              </TableCell>
              <TableCell>
                <LogData dat={item} />
              </TableCell>
              <TableCell>
                <Badge color={item.confirmed ? "green" : "red"}>
                  {item.confirmed ? "confirmed" : "init"}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
