import { useEffect, useState } from "react";
import { cancelGameChatGames, getUnfinishedChatGames } from "./dao";
import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import { showSuccessNotification } from "../comps/errorMessagesComp";

export default function UnfinishedGames() {
  const [unfinished, setUnfinished] = useState([]);
  const [betsLoading, setBetsLoading] = useState({});

  useEffect(() => {
    getBets();
  }, []);

  function getBets() {
    getUnfinishedChatGames().then((p) => setUnfinished(p));
  }

  async function cancelGame(gameId, betId) {
    setBetsLoading((p) => ({ ...p, [betId]: true }));
    try {
      await cancelGameChatGames(gameId, betId);
      showSuccessNotification("Cancelled and refunded");
      getBets();
    } catch (error) {
      showSuccessNotification("Error");
    }
    setBetsLoading((p) => ({ ...p, [betId]: false }));
  }
  return (
    <>
      <Table>
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Game
            </TableHeaderCell>

            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Amount
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Players
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Status
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              removed
              <br />
              balance
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Date
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Action
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unfinished.map((bet) => (
            <TableRow key={bet.id}>
              <TableCell className="break-words whitespace-normal text-sm p-0">
                {bet.game_type.split(" ").map((g) => (
                  <div>{g}</div>
                ))}
              </TableCell>
              <TableCell>
                {bet.amount_per_user.slice(0, 7)} {bet.currency.toUpperCase()}
              </TableCell>
              <TableCell>
                {bet.users.map((u) => (
                  <div>{u}</div>
                ))}
              </TableCell>
              <TableCell className="whitespace-normal text-sm p-0">
                <Badge>{bet.status}</Badge>
              </TableCell>
              <TableCell>
                {bet.game.hasRemovedBalances ? "Yes" : "no"}
              </TableCell>
              <TableCell>
                {bet.timestamp.slice(8, 19).replace("T", " ")}
              </TableCell>
              <TableCell>
                {bet.status !== "cancelled" && (
                  <Button
                    loading={betsLoading[bet.id]}
                    onClick={() => cancelGame(bet.game.apiId, bet.id)}
                  >
                    Cancel
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
