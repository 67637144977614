import { useEffect, useState } from "react";
import { auth, db, getBaseData, logOut } from "./firebase";
import LoginPage from "./loginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PagesContainer from "./pagesContainer";
import { useTheme } from "./theme";

import { RColumn, Space } from "./comps/otherCOmps";
import { LoadingComp } from "./comps/loading";
import { Button, Callout, Card } from "@tremor/react";
import { BaseData } from "./util/const";

export default function StartingPage() {
  const [state, setState] = useState("loading");
  const [stateMsg, setStateMsg] = useState("logged_out");

  const { setNewSede } = useTheme();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUserm) => {
      if (authUserm) {
        handleLogged(authUserm.uid);
      } else {
        setState("logged_out");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  async function handleLogged(uid) {
    try {
      setState("loading");
      const baseIn = await getBaseData();
      BaseData.baseInfo = baseIn;

      setState("logged_in");
    } catch (error) {
      console.log(error);
      setState("error");
      setStateMsg("Errore generico");
    }
  }

  if (state === "loading") {
    return (
      <RColumn verticalAlignment="center" height={"90vh"}>
        <LoadingComp />
      </RColumn>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      {state === "logged_in" && <PagesContainer />}
      {state === "logged_out" && <LoginPage />}

      {state === "error" && (
        <>
          <div className="flex justify-center items-center h-screen">
            <Card className="max-w-xl">
              <Callout title="Errore">{stateMsg}</Callout>
              <Space />
              <div className="space-x-3">
                <Button onClick={logOut}>Logout</Button>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
