import { Tit2Tremor } from "./coolComp";
import { RColumn, Space } from "./otherCOmps";

export const LoadingComp = () => {
  return (
    <RColumn>
      <Space />
      <Tit2Tremor>Loading</Tit2Tremor>
    </RColumn>
  );
};
