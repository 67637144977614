import {
  Badge,
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { getReferralLead, getTasksLead } from "../firebase";
import { RGrid } from "../comps/otherCOmps";

export default function TaskUserLead() {
  const [refLead, setRefLead] = useState([]);

  useEffect(() => {
    getTasksLead().then((lead) => setRefLead(lead));
  }, []);
  //num_max_completed
  return (
    <>
      <Table className="mt-8 w-full">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Uid / Tgid
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Task completed
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Max day <br />
              completed (tot)
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Tasks completed
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refLead.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>{item.uid}</TableCell>
              <TableCell>{item.num_completed}</TableCell>
              <TableCell>{item.num_max_completed ?? 0}</TableCell>
              <TableCell className="break-words whitespace-normal flex-grow">
                <RGrid>
                  {Object.entries(item.tasks)
                    .sort()
                    .map(([taskName, taskData]) => (
                      <Badge
                        color={taskData.num_today ? "violet" : "blue"}
                        style={{ margin: "2px 2px" }}
                        key={taskName}
                      >
                        {taskName}
                      </Badge>
                    ))}
                </RGrid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
