import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { getBaseData, getP2ELeaderboards } from "../firebase";
import SingleLead from "./leadSingle";
import { Badge, Select, SelectItem } from "@tremor/react";
import { BaseData } from "../util/const";
import { Space } from "../comps/otherCOmps";
import { RiLoader4Line } from "@remixicon/react";

export default function P2EPage() {
  const [leads, setLeads] = useState([]);
  const [selectedGameUrl, setSelectedGameUrl] = useState("");

  useEffect(() => {
    getP2ELeaderboards().then((l) => {
      setLeads(l);
      if (l.length > 0) setSelectedGameUrl(l[0].gameUrl); // Set default selection
    });
  }, []);

  function isGameActive(url) {
    return BaseData.baseInfo.p2eGames.games_active.some((g) => g.url === url);
  }
  function isGameOld(url) {
    return BaseData.baseInfo.p2eGames.games_old.some((g) => g.url === url);
  }

  const handleGameChange = (selectedValue) => {
    setSelectedGameUrl(selectedValue);
  };

  const selectedLead = leads.find((lead) => lead.gameUrl === selectedGameUrl);
  if (leads.length === 0) {
    return <RiLoader4Line className="animate-spin" size={20} />;
  }
  return (
    <>
      <Tit2Tremor>P2E</Tit2Tremor>
      <Space />

      <Select
        value={selectedGameUrl}
        onChange={handleGameChange}
        style={{ width: "320px" }}
      >
        {leads.map((lead) => (
          <SelectItem key={lead.gameUrl} value={lead.gameUrl}>
            {BaseData.baseInfo.gamesName[lead.gameUrl]}
            {isGameActive(lead.gameUrl) || isGameOld(lead.gameUrl) ? (
              <Badge
                color={isGameActive(lead.gameUrl) ? "green" : undefined}
                className="ml-2"
              >
                {isGameActive(lead.gameUrl)
                  ? "Active"
                  : isGameOld(lead.gameUrl)
                  ? "In ranks"
                  : ""}
              </Badge>
            ) : null}
          </SelectItem>
        ))}
      </Select>
      {selectedLead && <SingleLead data={selectedLead} />}
    </>
  );
}
