import axios from "axios";
import { auth } from "../firebase";

export async function addGroup() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    await axios.post(
      `https://uno.thegamehub.gg:3050/chatgames/addgroup`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
  }
}

export async function getLastGroups() {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.get(
      `https://uno.thegamehub.gg:3050/chatgames/groups`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Errore durante il recupero delle ultime scommesse:", error);
    return [];
  }
}
