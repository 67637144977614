import { RiLogoutBoxLine } from "@remixicon/react";
import { Button } from "@tremor/react";
import { Space } from "../comps/otherCOmps";
import { logOut } from "../firebase";
import { supabase } from "../supabase";

export default function UserSettingsTab() {
  supabase.auth.getUser().then((p) => console.log(p.data));
  return (
    <>
      <Space />

      <Button
        variant="secondary"
        icon={RiLogoutBoxLine}
        onClick={() => logOut()}
      >
        Logout
      </Button>
    </>
  );
}
