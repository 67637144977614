import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import {
  RColumn,
  RGrid,
  RRow,
  Space,
  SpaceHorizontal,
} from "../comps/otherCOmps";
import {
  AreaChart,
  Badge,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import {
  cancelGame,
  getBestGamesChatGames,
  getBestGroupsChatGames,
  getLastChatGames,
  getStatsChatGames,
} from "./dao";
import { formatNumberInStr, getPriceToken } from "../util/util";
import {
  RiCircleFill,
  RiCircleLine,
  RiDoubanFill,
  RiIdCardFill,
} from "@remixicon/react";

export default function ChatGames() {
  const [statsGames, setStatsGames] = useState();
  const [bestGames, setBestGames] = useState([]);
  const [showInGraphs, setShowInGraphs] = useState({});

  const chartIds = ["24h", "7d"];
  const [currRate, setCurrRate] = useState({});
  const currs = ["eth", "sol", "ton", "usdt", "usdc", "ghub"];

  useEffect(() => {
    getStatsChatGames().then((p) => setStatsGames(p));
    getBestGamesChatGames().then((p) => setBestGames(p));
    getBestGroupsChatGames();
    currs.forEach((c) => {
      getPriceToken(c).then((p) => setCurrRate((pr) => ({ ...pr, [c]: p })));
    });
    let newShowInG = {};
    chartIds.forEach((id) => {
      newShowInG[id] = ["totalBets", "totalProfit", "totalVolume"];
    });
    setShowInGraphs(newShowInG);
  }, []);

  if (!statsGames) {
    return <>loading</>;
  }

  function ChartShowCat({ children, chartId, field }) {
    const isSelected = (showInGraphs[chartId] ?? []).includes(field);
    return (
      <Card
        className="px-2 py-1 text-tremor-label w-32 cursor-pointer"
        style={{
          border: isSelected ? "1px solid blue" : undefined,
        }}
        onClick={() =>
          setShowInGraphs((p) => {
            const currentFields = p[chartId] ?? [];
            return {
              ...p,
              [chartId]: currentFields.includes(field)
                ? currentFields.filter((f) => f !== field) // Rimuove il field se esiste
                : [...currentFields, field], // Aggiunge il field se non esiste
            };
          })
        }
      >
        <RRow>
          {!isSelected && <RiCircleLine color="lightblue" size={18} />}
          {isSelected && <RiCircleFill color="lightblue" size={18} />}
          <SpaceHorizontal width="6px" />
          {children}
        </RRow>
      </Card>
    );
  }

  return (
    <>
      <Tit2Tremor>chatgames</Tit2Tremor>
      <Space />
      {/*   <RGrid>
        {statsGames.map((stat, i) => (
          <Card key={stat.currency} className="mb-3 w-80 mx-1">
            <dt className="text-tremor-default font-medium dark:text-dark-tremor-content">
              {stat.currency}
            </dt>

            <RRow>
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                tot bets: {stat.total_bets}
              </p>
              <SpaceHorizontal />
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                for $
                {formatNumberInStr(
                  stat.total_bets_amount * (currRate[stat.currency] ?? 0)
                )}
              </p>
            </RRow>
            <Space height="6px" />
            <RRow>
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                player loss {stat.total_losses}
              </p>
              <SpaceHorizontal />
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                for $
                {formatNumberInStr(
                  stat.total_losses_amount * (currRate[stat.currency] ?? 0)
                )}
              </p>
            </RRow>
            <SpaceHorizontal />
            <RRow>
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                player wins {stat.total_wins}
              </p>
              <SpaceHorizontal />
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                for $
                {formatNumberInStr(
                  stat.total_wins_amount * (currRate[stat.currency] ?? 0)
                )}
              </p>
            </RRow>
            <SpaceHorizontal />
            <RRow>
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                player draw {stat.total_draw}
              </p>
              <SpaceHorizontal />
              <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                for $
                {formatNumberInStr(
                  stat.total_draw_amount * (currRate[stat.currency] ?? 0)
                )}
              </p>
            </RRow>
          </Card>
        ))}
      </RGrid> */}
      <RGrid>
        <Card className="w-52 mx-2 my-2">
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total bets
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(parseInt(statsGames.totalBets))}
            </span>
          </dd>
        </Card>
        <Card className="w-52 mx-2 my-2">
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total volume
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              ${formatNumberInStr(statsGames.totalVolume)}
            </span>
          </dd>
        </Card>
        <Card className="w-52 mx-2 my-2">
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total profit
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              ${formatNumberInStr(statsGames.totalProfit)}
            </span>
          </dd>
        </Card>
        <Card className="w-60 mx-2 my-2 p-5">
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Last 24h
          </dt>
          <Space height="4px" />
          <RRow>
            <RColumn horizontalAlign="start">
              <dt className="text-tremor-title font-bold text-tremor-content-strong dark:text-dark-tremor-content">
                {formatNumberInStr(statsGames.last24Hours?.totalBets)}
              </dt>
              <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                bets
              </dt>
            </RColumn>
            <SpaceHorizontal width="22px" />
            <RColumn horizontalAlign="start">
              <dt className="text-tremor-title font-bold text-tremor-content-strong dark:text-dark-tremor-content">
                ${formatNumberInStr(statsGames.last24Hours?.totalVolume)}
              </dt>
              <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                volume
              </dt>
            </RColumn>
            <SpaceHorizontal width="22px" />
            <RColumn horizontalAlign="start">
              <dt className="text-tremor-title font-bold text-tremor-content-strong dark:text-dark-tremor-content">
                ${formatNumberInStr(statsGames.last24Hours?.totalProfit)}
              </dt>
              <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                profit
              </dt>
            </RColumn>
          </RRow>
        </Card>
      </RGrid>
      <div className="flex flex-row gap-x-2 mt-5 items-end">
        <Tit2Tremor>Last 24 hours</Tit2Tremor>
        <SpaceHorizontal />
        <ChartShowCat chartId={chartIds[0]} field="totalVolume">
          Volume
        </ChartShowCat>
        <ChartShowCat chartId={chartIds[0]} field="totalProfit">
          Profit
        </ChartShowCat>
        <ChartShowCat chartId={chartIds[0]} field="totalBets">
          Num bets
        </ChartShowCat>
      </div>
      <AreaChart
        data={statsGames.hourlyStats ?? []}
        index="date"
        categories={showInGraphs[chartIds[0]]}
        colors={["blue", "violet", "red"]}
        valueFormatter={formatNumberInStr}
        showLegend={false}
        showYAxis={true}
        showGradient={true}
        startEndOnly={true}
        className="mt-6 h-60"
      />
      <div className="flex flex-row gap-x-2 mt-5 items-end">
        <Tit2Tremor>Last 7 days</Tit2Tremor>
        <SpaceHorizontal />
        <ChartShowCat chartId={chartIds[1]} field="totalVolume">
          Volume
        </ChartShowCat>
        <ChartShowCat chartId={chartIds[1]} field="totalProfit">
          Profit
        </ChartShowCat>
        <ChartShowCat chartId={chartIds[1]} field="totalBets">
          Num bets
        </ChartShowCat>
      </div>
      <AreaChart
        data={statsGames.dailyStats ?? []}
        index="date"
        categories={showInGraphs[chartIds[1]]}
        colors={["blue", "violet", "red"]}
        valueFormatter={formatNumberInStr}
        showLegend={false}
        showYAxis={true}
        showGradient={true}
        startEndOnly={true}
        className="mt-6 h-60"
      />
      <Space />
      <RGrid>
        <Space />
        {bestGames.map((g) => (
          <Card
            key={g.gameType}
            className="mb-3 mx-1"
            style={{ width: "100%" }}
          >
            <Tit2Tremor>{g.gameType}</Tit2Tremor>
            <Table>
              <TableHead>
                <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Curr
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    bets
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    bets amt
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    losses
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    losses amt
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    wins
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    wins amt
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {g.currency.map((curr) => (
                  <TableRow key={curr.currency + g.gameType}>
                    <TableCell>{curr.currency}</TableCell>
                    <TableCell>{curr.total_bets}</TableCell>
                    <TableCell>
                      $
                      {formatNumberInStr(
                        curr.total_bets_amount * currRate[curr.currency] ?? 0
                      )}
                    </TableCell>
                    <TableCell>{curr.total_losses}</TableCell>
                    <TableCell>
                      $
                      {formatNumberInStr(
                        curr.total_losses_amount * currRate[curr.currency] ?? 0
                      )}
                    </TableCell>
                    <TableCell>{curr.total_wins}</TableCell>
                    <TableCell>
                      $
                      {formatNumberInStr(
                        curr.total_wins_amount * currRate[curr.currency] ?? 0
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        ))}
      </RGrid>
    </>
  );
}
