import {
  Badge,
  Button,
  Card,
  NumberInput,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
  Textarea,
  TextInput,
} from "@tremor/react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { RColumn, RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { BaseData } from "../util/const";
import { getTimeDifferenceStr } from "../util/util";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export default function SingleLead({ data }) {
  const [rewToSend, setRewToSend] = useState({
    0: { amountTkn: "" },
  });
  const [selectedCurrRew, setSelectedCurrRew] = useState("ghub");

  const [numPlayerToRewards, setNumPlayerToReward] = useState(0);
  const [stateRews, setStateRews] = useState("init");
  const currencys = ["ghub", "eth"];
  const [resFromRew, setResFromRew] = useState();

  useEffect(() => {
    console.log(data.gameUrl);
    let r = {};
    [...Array(numPlayerToRewards).keys()].forEach((n) => {
      r[n] = { amountTkn: "" };
    });
    setRewToSend(r);
  }, [numPlayerToRewards, selectedCurrRew]);

  async function sendPrizes() {
    if (stateRews === "init") setStateRews("loading_check");
    if (stateRews === "no_confirmed") setStateRews("loading_confirm");

    try {
      const funct = httpsCallable(functions, "sendP2EPoints2");
      const res = await funct({
        gameUrl: data.gameUrl,
        confirm: stateRews === "no_confirmed",
        numRews: numPlayerToRewards,
        currency: selectedCurrRew,
        rewards: rewToSend,
      });
      setStateRews(res.data !== "ok" ? "no_confirmed" : "confirmed");

      setResFromRew(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <RRow verticalAlignment="start">
        <RColumn horizontalAlign="start">
          <Table className="mt-2">
            <TableHead>
              <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  rank
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Username
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  score
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  date
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.top50.map((item) => (
                <TableRow key={item.uid + data.gameUrl}>
                  <TableCell>{item.rank}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.score}</TableCell>
                  <TableCell>
                    {new Date(item.date)
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </RColumn>
        <SpaceHorizontal width="30px" />
        <RColumn horizontalAlign="start" width={"40%"}>
          <Tit2Tremor>Last rewards given</Tit2Tremor>
          {data.last_rewards_date && (
            <p className="text-base">
              {data.last_rewards_date?.slice(0, 19).replace("T", " ")}
              <span className="text-gray-500 text-sm">
                ({getTimeDifferenceStr(data.last_rewards_date)} ago)
              </span>
            </p>
          )}
          <Space />
          <Badge color={"green"}>{data.tot_players} players</Badge>

          <Space />
          {stateRews === "init" && (
            <>
              <RRow>
                <Tit2Tremor>Num of players to reward</Tit2Tremor>
                <Select
                  value={numPlayerToRewards}
                  onChange={(e) => setNumPlayerToReward(e)}
                >
                  {[...Array(30).keys()].map((n) => (
                    <SelectItem key={n + 1} value={n + 1}>
                      {n + 1}
                    </SelectItem>
                  ))}
                </Select>
              </RRow>
              <Space />
              <div className="w-32">
                <Select
                  value={selectedCurrRew}
                  onChange={(e) => setSelectedCurrRew(e)}
                >
                  {currencys.map((c) => (
                    <SelectItem key={c} value={c}>
                      {c}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              {[...Array(numPlayerToRewards).keys()].map((n) => (
                <Card key={n + "dd"} className="mt-2 p-4">
                  <RRow>
                    <Tit2Tremor>{n + 1}°</Tit2Tremor>
                    <SpaceHorizontal />
                    <NumberInput
                      placeholder={"amount " + selectedCurrRew}
                      min={0}
                      value={rewToSend[n]?.amountTkn}
                      onChange={(e) =>
                        setRewToSend((p) => ({
                          ...p,
                          [n]: { amountTkn: parseFloat(e.target.value) },
                        }))
                      }
                    />
                  </RRow>
                </Card>
              ))}
            </>
          )}
          {stateRews === "no_confirmed" && (
            <>
              {resFromRew.map((u) => (
                <Card key={u.uid} className="p-3">
                  <RRow>
                    <Tit2Tremor>{u.username}</Tit2Tremor>
                    <SpaceHorizontal />+ {u.reward.amountTkn}
                    {u.reward.currency}
                  </RRow>
                  <DescTremor>score: {u.score}</DescTremor>
                </Card>
              ))}
            </>
          )}
          {stateRews === "confirmed" && (
            <>
              <Tit2Tremor>Prizes sended!</Tit2Tremor>
            </>
          )}
          <Space />
          {(stateRews === "init" || stateRews === "no_confirmed") && (
            <Button
              loading={stateRews.includes("loading")}
              onClick={sendPrizes}
            >
              {stateRews !== "no_confirmed" ? "Check" : "Send rewards"}
            </Button>
          )}
        </RColumn>
      </RRow>
    </>
  );
}
